function tableToExcel(tableid, sheetName) {
  let uri = 'data:application/vnd.ms-excel;base64,';
  let template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel"' +
    'xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>'
    + '<x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets>'
    + '</x:ExcelWorkbook></xml><![endif]-->' +
    ' <style type="text/css">' +
    'table td {' +
    'height: 25px;' +
    'font-family: 宋体;' +
    'font-size: 13px;' +
    'text-align: left;' +
    'border: 0.5px solid #000000;' +
    ' }' +
    'table th {' +
    'border: 0.5px solid #000000;' +
    ' }' +
    'table .tHead {' +
    'font-size: 20px;' +
    'text-align:center;' +
    'font-weight: bold;' +
    'height: 40px;' +
    ' }' +
    'table tfoot tr{' +
    'height: 25px;' +
    ' }' +
    'table tbody td{' +
    'text-align: center;' +
    'height: 20px;' +
    'mso-number-format:"@";' +
    ' }' +
    '</style>' +
    '</head><body ><table class="excelTable">{table}</table></body></html>';
  if (!tableid.nodeType) tableid = document.getElementById(tableid);
  let ctx;
  if (tableid) {
    ctx = { worksheet: sheetName || 'Worksheet', table: tableid.innerHTML };
    document.getElementById('simulation').href = uri + base64(format(template, ctx));
    document.getElementById('simulation').download = sheetName + ".xls";//自定义文件名
    document.getElementById('simulation').click();
  }
}
function base64(s) {
  return window.btoa(unescape(encodeURIComponent(s)));
}
function format(s, c) {
  return s.replace(/{(\w+)}/g,
    function (m, p) {
      return c[p];
    });
}
export default tableToExcel